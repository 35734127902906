<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="white"/>
    <path d="M45.9958 12.3511C45.7738 12.1668 45.5039 12.0493 45.2177 12.0125C44.9315 11.9756 44.6407 12.0208 44.3792 12.1429L13 26.8717V29.7217L26.1799 34.9936L34.6339 48H37.4845L46.4914 13.9042C46.5644 13.6249 46.5566 13.3307 46.4689 13.0557C46.3811 12.7808 46.2171 12.5364 45.9958 12.3511ZM35.7246 45.2817L28.4579 34.1019L39.64 21.8545L37.8707 20.2391L26.6011 32.5819L15.7343 28.2352L43.694 15.1111L35.7246 45.2817Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "welcomeEmailIcon"
    }
</script>
<style scoped></style>