<div class="dashboard-right-inner h-100">
    <Breadcrumb :id="$route.params.id" />
    <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
    <app-loader v-if="isLoading"></app-loader>
    <div class="max-height-100--60 min-h-100vh--60 overflow-y-auto">
        <div class="dashboard-header d-flex flex-wrap justify-content-between align-items-end pb-20">
            <div class="w-100-vsm">
                <h2 class="font-28 font-34-sm-big font-w-300">Create Flow Automation</h2>
                <!-- <p class="font-14 color-light2">
                    Create exciting campaigns to engage with your club members for promotions and club activities.
                </p> -->
            </div>
            <div class="w-100-vsm">
                <kbutton :fill-mode="'flat'" :class="'pl-0-vsm'" @click="doNavigateToFlowAutomationHomepage">Cancel
                </kbutton>
            </div>
        </div>
        <div class="dashboard-content p-0 min-h-100vh--152">
            <div
                class="create-flow-wrap create-campaign-wrap d-flex justify-content-between min-h-100vh--152">
                <div class="w-50-p min-h-100vh--152">
                    <div
                        class="create-flow-left create-campaign-left p-32">
                        <div class="w-530">
                            <div class="k-form-field mb-32">
                                <label class="font-16">Give Your Flow A Name *</label>
                                <!-- @keyup="errors.Name[0]=''" -->
                                <k-input type="text" v-model="flowName" placeholder="AFL Summer Campaign 2023"
                                    :class="'mb-32'" aria-required="true">
                                </k-input>
                                <span class="k-form-error">{{this.errors?.Name?.[0]}}</span>
                                <div class="col-md-12">
                                    <label class="" for="">Select A Flow Template</label>
                                    <div @click="selectFlowTemplateHandler" ref="buildFromScratchRef"
                                        :class="'d-flex justify-content-between align-items-center h-38 bg-white radius-4 build-scratch-input'">
                                        <span>{{flowTypeNames?.[flowType]?.name}}</span>
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 11L4 5H12L8 11Z" fill="#24243A" />
                                            </svg>
                                        </span>
                                    </div>
                                    <Popup :anchor="'buildFromScratchRef'" :show="selectFlowTemplatePopupVisibility"
                                        :popup-class="'build-scratch-popup w-530'">
                                        <div v-click-outside="onCancelFlowTemplatePopup" class="build-scratch-popup-wrap">
                                            <div @click="flowTemplateSelectedHandler('buildFromScratch')"
                                                :class="['build-scratch-box p-16 d-flex radius-4', [1].includes(flowTypeNames?.[flowType]?.value) ? 'selected' : '']">
                                                <div class="icon pe-2">
                                                    <component is="buildFromScratchIcon"></component>
                                                </div>
                                                <div class="content">
                                                    <h3 class="font-19 l-height-19 font-w-400 color-black mb-2">Build from
                                                        scratch</h3>
                                                    <p class="font-16 l-height-24 font-w-400 color-gray mb-0">Use our drag
                                                        and drop features to create exiciting email designs.</p>
                                                </div>
                                            </div>
                                            <div @click="flowTemplateSelectedHandler('abandonedCardFlow')"
                                                :class="['build-scratch-box p-16 d-flex radius-4', [2].includes(flowTypeNames?.[flowType]?.value) ? 'selected' : '']">
                                                <div class="icon pe-2">
                                                    <component is="abandonedCardFlowicon"></component>
                                                </div>
                                                <div class="content">
                                                    <h3 class="font-19 l-height-19 font-w-400 color-black mb-2">Abandoned
                                                        Card Flow</h3>
                                                    <p class="font-16 l-height-24 font-w-400 color-gray mb-0">Template
                                                        created based on best industry practices for abandored cart email
                                                        automation.</p>
                                                </div>
                                            </div>
                                            <div @click="flowTemplateSelectedHandler('welcomeEmail')"
                                                :class="['build-scratch-box p-16 d-flex radius-4', [3].includes(flowTypeNames?.[flowType]?.value) ? 'selected' : '']">
                                                <div class="icon pe-2">
                                                    <component is="welcomeEmailIcon"></component>
                                                </div>
                                                <div class="content">
                                                    <h3 class="font-19 l-height-19 font-w-400 color-black mb-2">Welcome
                                                        Email</h3>
                                                    <p class="font-16 l-height-24 font-w-400 color-gray mb-0">Template
                                                        created based on best industry practices for welcoming new
                                                        subscribers or members to the organisation.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </div>
                            </div>
                            <kbutton :theme-color="'primary'" :disabled="!flowName" :class="'w-100'"
                                @click="handleCreateEvent">Continue</kbutton>
                        </div>
                    </div>
                </div>
                <div class="w-50-p min-h-100vh--152">
                    <div
                        class="create-flow-right create-campaign-right p-44 h-100 bg-white">
                        <div v-if="this.creationTypeSerial != 4"
                            class="p-42 d-flex justify-content-center align-items-center h-100">
                            <div class="text-center">
                                <div class="campaign-banner">
                                    <component is="createFlowAutomationBanner"></component>
                                </div>
                                <p class="text-center color-gray mt-20 mb-0">Create a flow automation using our drag and
                                    drop flow automation builder.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>