<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="white"/>
    <path d="M39.9943 24.6638C39.5426 25.1126 38.9317 25.3645 38.2949 25.3645C37.6582 25.3645 37.0473 25.1126 36.5956 24.6638L34.5999 22.6682C34.1507 22.2165 33.8985 21.6054 33.8985 20.9684C33.8985 20.3314 34.1507 19.7203 34.5999 19.2687L39.117 14.7493C39.126 14.7407 39.1326 14.73 39.1364 14.7182C39.1402 14.7064 39.1411 14.6939 39.1388 14.6817C39.1366 14.6695 39.1314 14.658 39.1237 14.6483C39.1159 14.6386 39.1059 14.631 39.0945 14.6261C36.1787 13.3763 32.3984 14.0132 29.9979 16.3965C27.7011 18.6761 27.5704 22.1048 28.4116 25.0972C28.5311 25.5226 28.5308 25.9727 28.4107 26.3978C28.2907 26.823 28.0555 27.2068 27.7311 27.5068L15.2178 38.9347C14.8486 39.2621 14.5502 39.6615 14.3411 40.1084C14.132 40.5553 14.0164 41.0402 14.0016 41.5334C13.9868 42.0266 14.0731 42.5176 14.255 42.9762C14.437 43.4348 14.7108 43.8514 15.0597 44.2003C15.4086 44.5492 15.8252 44.823 16.2838 45.005C16.7424 45.1869 17.2334 45.2732 17.7266 45.2584C18.2198 45.2436 18.7047 45.128 19.1516 44.9189C19.5985 44.7098 19.9979 44.4114 20.3253 44.0422L31.8817 31.4989C32.1778 31.1799 32.5552 30.9476 32.9733 30.8268C33.3914 30.706 33.8345 30.7013 34.2552 30.8131C37.225 31.6085 40.605 31.459 42.8718 29.2215C45.3121 26.818 45.8386 22.5698 44.6556 20.1385C44.6503 20.1278 44.6425 20.1186 44.633 20.1116C44.6234 20.1046 44.6123 20.0999 44.6005 20.098C44.5888 20.0961 44.5768 20.097 44.5655 20.1006C44.5542 20.1043 44.5439 20.1105 44.5355 20.1189L39.9943 24.6638Z" stroke="#404A61" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M17.823 41.6383C17.823 41.7497 17.7326 41.84 17.6212 41.84C17.5098 41.84 17.4194 41.7497 17.4194 41.6383C17.4194 41.5269 17.5098 41.4365 17.6212 41.4365C17.7326 41.4365 17.823 41.5269 17.823 41.6383Z" fill="black" stroke="#404A61" stroke-width="2"/>
    </svg>
</template>
<script>
    export default {
        name: "buildFromScratchIcon"
    }
</script>
<style scoped></style>