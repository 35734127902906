<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="white"/>
    <path d="M24.4344 47.42C25.888 47.42 27.0665 46.2416 27.0665 44.7879C27.0665 43.3342 25.888 42.1558 24.4344 42.1558C22.9807 42.1558 21.8022 43.3342 21.8022 44.7879C21.8022 46.2416 22.9807 47.42 24.4344 47.42Z" fill="#404A61"/>
    <path d="M40.4261 47.42C41.8797 47.42 43.0582 46.2416 43.0582 44.7879C43.0582 43.3342 41.8797 42.1558 40.4261 42.1558C38.9724 42.1558 37.7939 43.3342 37.7939 44.7879C37.7939 46.2416 38.9724 47.42 40.4261 47.42Z" fill="#404A61"/>
    <path d="M47.5386 16.2689C47.4298 16.1347 47.2925 16.0263 47.1368 15.9514C46.981 15.8766 46.8107 15.8372 46.6379 15.836H22.282L23.0424 18.1757H45.1054L41.9819 32.2136H24.4345L19.0884 15.2862C19.0305 15.1066 18.9301 14.9436 18.7957 14.8112C18.6612 14.6788 18.4968 14.5809 18.3163 14.5258L13.52 13.0518C13.3725 13.0065 13.2175 12.9907 13.0639 13.0052C12.9103 13.0198 12.7611 13.0645 12.6248 13.1367C12.3495 13.2826 12.1434 13.5319 12.0518 13.8298C11.9603 14.1276 11.9908 14.4496 12.1367 14.7249C12.2826 15.0003 12.5319 15.2064 12.8298 15.2979L17.0295 16.5847L22.399 33.5473L20.4805 35.1148L20.3284 35.2669C19.8538 35.8138 19.5848 36.509 19.5678 37.2329C19.5507 37.9568 19.7866 38.664 20.2348 39.2326C20.5536 39.6204 20.9588 39.9281 21.4179 40.1313C21.877 40.3344 22.3772 40.4273 22.8786 40.4025H42.4031C42.7133 40.4025 43.0109 40.2792 43.2303 40.0598C43.4497 39.8404 43.5729 39.5429 43.5729 39.2326C43.5729 38.9224 43.4497 38.6248 43.2303 38.4054C43.0109 38.186 42.7133 38.0628 42.4031 38.0628H22.6914C22.5567 38.0582 22.4255 38.0189 22.3104 37.9488C22.1953 37.8787 22.1002 37.78 22.0343 37.6624C21.9685 37.5448 21.9341 37.4122 21.9344 37.2774C21.9348 37.1426 21.9699 37.0102 22.0363 36.893L24.8556 34.5533H42.9178C43.1882 34.5599 43.4526 34.4726 43.6659 34.3062C43.8792 34.1399 44.0282 33.9047 44.0876 33.6408L47.796 17.2632C47.8316 17.0888 47.8269 16.9086 47.7823 16.7363C47.7377 16.564 47.6544 16.4041 47.5386 16.2689Z" fill="#404A61"/>
    </svg>
</template>
<script>
    export default {
        name: "abandonedCardFlowicon"
    }
</script>
<style scoped></style>