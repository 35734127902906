<template src="./createFlowAutomation.html"></template>
<style lang="scss">
@import "./createFlowAutomation.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar";
import AppLoader from "@/components/common/AppLoader/AppLoader";
import { Popup } from "@progress/kendo-vue-popup";
import vClickOutside from "click-outside-vue3";
import createFlowAutomationBanner from "@/components/svg-icons/createFlowAutomationBanner.vue";
import buildFromScratchIcon from "@/components/svg-icons/buildFromScratchIcon.vue";
import abandonedCardFlowicon from "@/components/svg-icons/abandonedCardFlowicon.vue";
import welcomeEmailIcon from "@/components/svg-icons/welcomeEmailIcon.vue";
import { verifyAutoFlowNameService } from "@/services/clubDetails/flowAutomation.service";

export default {
  name: "createFlowAutomation",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    kbutton: Button,
    "k-input": Input,
    dropdownlist: DropDownList,
    Breadcrumb,
    AppSnackBar,
    AppLoader,
    createFlowAutomationBanner,
    buildFromScratchIcon,
    abandonedCardFlowicon,
    welcomeEmailIcon,
    Popup,
  },
  data() {
    return {
      isLoading: false,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      flowName: "",
      selectFlowTemplatePopupVisibility: false,
      flowType: "buildFromScratch",
      flowTypeNames: {
        buildFromScratch: {
          name: "Build from scratch",
          value: 1,
        },
        abandonedCardFlow: {
          name: "Abandoned card flow",
          value: 2,
        },
        welcomeEmail: {
          name: "Welcome email",
          value: 3,
        },
      },
    };
  },
  created() { },
  mounted() {
    if (!this.hasFlowCampaignPostPermission()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to flow automation.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
  },
  computed: {},
  methods: {
    hasFlowCampaignPostPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    dataValidation() {
      if (!this.flowName) {
        this.showNotification("Flow name is required.", "warning");
        return false;
      }
      if (this.flowName.length > 100) {
        this.showNotification(
          "Flow name should not exceed 100 characters.",
          "warning"
        );
        return false;
      }
      if (this.flowName.trim().length === 0) {
        this.showNotification("Flow name should not be empty.", "warning");
        return false;
      }
      return true;
    },
    selectFlowTemplateHandler() {
      this.selectFlowTemplatePopupVisibility =
        !this.selectFlowTemplatePopupVisibility;
    },
    onCancelFlowTemplatePopup() {
      this.selectFlowTemplatePopupVisibility = false;
    },
    flowTemplateSelectedHandler(type) {
      this.selectFlowTemplatePopupVisibility = false;
      this.flowType = type;
    },
    handleCreateEvent() {
      if (this.dataValidation()) {
        this.isLoading = true;
        const payload = {
          autoflowName: this.flowName,
          clubId: this.$route.params.id,
        };
        verifyAutoFlowNameService(payload)
          .then((response) => {
            this.isLoading = false;
            if (!response.data.data) {
              this.$router.push({
                name: "workflowEditor",
                query: {
                  flow_name: this.flowName,
                  flow_type: this.flowTypeNames?.[this.flowType]?.value,
                },
              });
            } else {
              this.showNotification("Flow name already exists.", "warning");
              return;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.showNotification("Something went wrong.", "error");
          });
      }
    },
    doNavigateToFlowAutomationHomepage() {
      this.$router.push({
        name: "flowAutomation",
      });
    },
  },
};
</script>
<style scoped></style>
